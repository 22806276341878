<template>
  <v-main>
    <v-card>
      <v-container>
        <v-card-title>ユーザー情報</v-card-title>
        <v-card-text>トークン: {{localUser.token}}</v-card-text>
        <v-card-text>ユーザー名: {{localUser.name}}</v-card-text>
        <v-card-text>UUID: {{localUser.uuid}}</v-card-text>
        <v-card-text>メール: {{localUser.email}}</v-card-text>
        <v-img contain height="250" :src="localUser.icon" />
        <v-row>
          <v-col>
            <v-btn large block color="success" class="font-weight-bold" v-on:click="onClick" :loading="loading">情報取得</v-btn>
          </v-col>
          <v-col>
            <v-btn large block color="success" class="font-weight-bold" v-on:click="onRegist" :loading="loading">登録</v-btn>
          </v-col>
          <v-col>
            <v-btn large block color="success" class="font-weight-bold" v-on:click="onSearch" :loading="loading">検索</v-btn>
          </v-col>
        </v-row>
        <!-- Snackbar -->
        <div>
          <v-snackbar
            v-model="snackbarFlag"
            :color="snackbarColor"
            :timeout="snackbarTimeout"
            :multi-line="true"
            top
          >
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbarFlag = false"
              >
                <v-icon
                right
                >
                mdi-close-circle-outline
              </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: Object
  },
  computed: {
    localUser: {
      get: function() {
        return this.user
      }
    }
  },
  data() {
    return {
      loading: false,
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  methods: {
    async onClick() {
      this.loading = true
      var me = this

      const params = new URLSearchParams()
      params.append('token', this.localUser.token)

      axios.post(process.env.VUE_APP_LINEVERIFY_API, params)
        .then(response => {
          me.loading = false
          console.log("resp="+JSON.stringify(response))
          const data = response.data
          me.localUser.uuid = data.userId
          me.localUser.name = data.displayName
          me.localUser.icon = data.pictureUrl
        }).catch(error => {
          me.loading = false
          console.log("errr="+ error);
      });
    },
    async onRegist() {
      this.loading = true
      var me = this

      const params = new URLSearchParams()
      params.append('token', this.localUser.token)

      axios.post(process.env.VUE_APP_USER_REGIST_API, params)
        .then(response => {
          me.loading = false
          me.showSnackbar('登録が完了しました', 'success')
          console.log("resp="+JSON.stringify(response))
        }).catch(error => {
          me.loading = false

          if (error.response.status == 409) {
            me.showSnackbar('既に登録済みです', 'error')
          } else {
            me.showSnackbar('登録に失敗しました', 'error')
          }
          console.log("status="+ error.response.status)
          console.log("errr="+ error);
      });
    },
    async onSearch() {
      this.loading = true
      var me = this

      const params = new URLSearchParams()
      params.append('token', this.localUser.token)

      axios.post(process.env.VUE_APP_USER_SEARCH_API, params)
        .then(response => {
          me.loading = false
          console.log("resp="+JSON.stringify(response))
          const data = response.data
          me.localUser.uuid = data.Item.userId
          me.localUser.name = data.Item.displayName
          me.localUser.icon = data.Item.pictureUrl
        }).catch(error => {
          me.loading = false
          console.log("errr="+ error);
      });
    },
    showSnackbar(text, color) {
      this.snackbarFlag = true
      this.snackbarText = text
      this.snackbarColor = color
    }
  }
}
</script>